//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Chart } from "@antv/g2";
export default {
  name: "Online",
  components: {
    Header: () => import("@/components/Header"),
    selMember: () => import("@/components/Selectors/MemberSelectCopy"),
  },
  data() {
    return {
      loading: false,
      onlineTeamListLoading: false,
      loginTeamListLoading: false,
      loginDataLoading: false,
      teamOptions: [], //团队选择器
      teamValue: null, //选择的团队
      selMem: [],
      ds: null, //开始时间
      de: null, //结束时间
      dateType: 1, //时间查询类型
      dateRange2PickerOptions: {
        disabledDate: (v) => v <= new Date(this.DateRange[0]),
      },
      selDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() + 1
      ),
      pickOption: this.pickDate(),
      DateRange: [
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 7
        ),
        new Date(),
      ],
      statisticsTotal: {
        onlineTeamCount: 0,
        onlineUserCount: 0,
        periodTeamCount: 0,
        periodUserCount: 0,
        loginTeamCount: 0,
        loginUserCount: 0,
      },
      onlineTeamList: [
        {
          Name: "示例团队",
          OnlineCount: 0,
          TotalCount: 0,
        },
      ],
      onlineTeamPageData: {
        pageIndex: 1,
        pageSize: 20,
        totalNum: 0,
      }, //分页
      loginTeamList: [
        {
          Name: "示例团队",
          LoginCount: 0,
          TotalCount: 0,
        },
      ],
      loginTeamPageData: {
        pageIndex: 1,
        pageSize: 20,
        totalNum: 0,
      }, //分页
      loginTeamCounts: [],
      loginUserCounts: [],
      loginTeamChart: null,
      loginUserChart: null,
    };
  },
  methods: {
    pickDate() {
      return {
        disabledDate(time) {
          return time.getTime() > new Date().getTime();
        },
      };
    },
    /**
     * 选择成员
     */
    getSelMember(arr) {
      this.selMem = arr;
    },
    /**
     * 获取团队
     */
    async getTeams() {
      const { res, data } = await this.$http.get("/Teams/GetAllTeams.ashx", {
        params: { searchText: null, type: 2 },
      });

      if (res === 0) {
        this.teamOptions = data;
      }
    },
    /**
     * 团队切换
     */
    handleTeamChange(v) {
      this.teamValue = v;
    },
    async handleGetData() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      await this.getStatisticsTotal();
      await this.getOnlineTeamData();
      await this.getLoginTeamData();
      await this.getLoginData();
      this.loading = false;
    },
    async getOnlineTeamData() {
      if (this.onlineTeamListLoading) {
        return;
      }
      this.onlineTeamListLoading = true;
      const day = new Date(this.selDate).getDay();
      const dayNum = day > 0 ? day - 1 : 6;
      const date = new Date(this.selDate);
      if (this.dateType == 1) {
        this.ds = new Date(date.setDate(date.getDate() - dayNum)).timeFormat(
          "yyyy-MM-dd 00:00:01"
        );
        const s = new Date(this.ds);
        this.de = new Date(s.setDate(s.getDate() + 6)).timeFormat(
          "yyyy-MM-dd 23:59:59"
        );
      } else if (this.dateType == 2) {
        this.ds = new Date(date.getFullYear(), date.getMonth(), 1).timeFormat(
          "yyyy-MM-dd 00:00:01"
        );
        this.de = new Date(
          date.getFullYear(),
          date.getMonth() + 1,
          0
        ).timeFormat("yyyy-MM-dd 23:59:59");
      } else {
        this.ds = this.DateRange[0].timeFormat("yyyy-MM-dd 00:00:01");
        this.de = this.DateRange[1].timeFormat("yyyy-MM-dd 23:59:59");
      }
      {
        const _ = new Date(this.de);
        const date = _.getDate();
        _.setDate(date + 1);
        this.de = _.timeFormat("yyyy-MM-dd 23:59:59");
      }
      var data = {
        stime: this.ds,
        etime: this.de,
        pageIndex: this.onlineTeamPageData.pageIndex,
        pageSize: this.onlineTeamPageData.pageSize,
        type: 1,
      };
      const { res, data: { Data, TotalCount } = {} } = await this.$http.post(
        "/Management/UserManagement/GetTeamClientStatistics.ashx",
        data
      );
      if (res == 0) {
        this.onlineTeamList = Data.map((m) => {
          return {
            Name: m.Name,
            OnlineCount: m.mCount,
            TotalCount: m.SumCount,
          };
        });
        this.onlineTeamPageData.totalNum = TotalCount;
      }
      this.onlineTeamListLoading = false;
    },
    async getLoginTeamData() {
      if (this.loginTeamListLoading) {
        return;
      }
      this.loginTeamListLoading = true;
      const day = new Date(this.selDate).getDay();
      const dayNum = day > 0 ? day - 1 : 6;
      const date = new Date(this.selDate);
      if (this.dateType == 1) {
        this.ds = new Date(date.setDate(date.getDate() - dayNum)).timeFormat(
          "yyyy-MM-dd 00:00:01"
        );
        const s = new Date(this.ds);
        this.de = new Date(s.setDate(s.getDate() + 6)).timeFormat(
          "yyyy-MM-dd 23:59:59"
        );
      } else if (this.dateType == 2) {
        this.ds = new Date(date.getFullYear(), date.getMonth(), 1).timeFormat(
          "yyyy-MM-dd 00:00:01"
        );
        this.de = new Date(
          date.getFullYear(),
          date.getMonth() + 1,
          0
        ).timeFormat("yyyy-MM-dd 23:59:59");
      } else {
        this.ds = this.DateRange[0].timeFormat("yyyy-MM-dd 00:00:01");
        this.de = this.DateRange[1].timeFormat("yyyy-MM-dd 23:59:59");
      }
      {
        const _ = new Date(this.de);
        const date = _.getDate();
        _.setDate(date + 1);
        this.de = _.timeFormat("yyyy-MM-dd 23:59:59");
      }
      var data = {
        stime: this.ds,
        etime: this.de,
        pageIndex: this.loginTeamPageData.pageIndex,
        pageSize: this.loginTeamPageData.pageSize,
        type: 1,
      };
      const { res, data: { Data, TotalCount } = {} } = await this.$http.post(
        "/Management/UserManagement/GetTeamLoginStatistics.ashx",
        data
      );
      if (res == 0) {
        this.loginTeamList = Data.map((m) => {
          return {
            Name: m.Name,
            LoginCount: m.mCount,
            TotalCount: m.SumCount,
          };
        });
        this.loginTeamPageData.totalNum = TotalCount;
      }
      this.loginTeamListLoading = false;
    },
    async getStatisticsTotal() {
      var data = {};
      const {
        res,
        data: { ulCount, tlCount, unCount, tnCount, ucCount, tcCount } = {},
      } = await this.$http.post(
        "/Management/UserManagement/GetUserStatisticsNum.ashx",
        data
      );
      if (res == 0) {
        this.statisticsTotal.onlineTeamCount = tcCount;
        this.statisticsTotal.onlineUserCount = ucCount;
        this.statisticsTotal.periodTeamCount = tnCount;
        this.statisticsTotal.periodUserCount = unCount;
        this.statisticsTotal.loginTeamCount = tlCount;
        this.statisticsTotal.loginUserCount = ulCount;
      }
    },
    handleSizeChange(val) {
      this.onlineTeamPageData.pageSize = val;
      this.getOnlineTeamData();
    },
    handleCurrentChange(val) {
      this.onlineTeamPageData.pageIndex = val;
      this.getOnlineTeamData();
    },
    handleSizeChangeLogin(val) {
      this.loginTeamPageData.pageSize = val;
      this.getLoginTeamData();
    },
    handleCurrentChangeLogin(val) {
      this.loginTeamPageData.pageIndex = val;
      this.getLoginTeamData();
    },
    async getLoginData() {
      if (this.loginDataLoading) {
        return;
      }
      this.loginDataLoading = true;
      const day = new Date(this.selDate).getDay();
      const dayNum = day > 0 ? day - 1 : 6;
      const date = new Date(this.selDate);
      if (this.dateType == 1) {
        this.ds = new Date(date.setDate(date.getDate() - dayNum)).timeFormat(
          "yyyy-MM-dd 00:00:01"
        );
        const s = new Date(this.ds);
        this.de = new Date(s.setDate(s.getDate() + 6)).timeFormat(
          "yyyy-MM-dd 23:59:59"
        );
      } else if (this.dateType == 2) {
        this.ds = new Date(date.getFullYear(), date.getMonth(), 1).timeFormat(
          "yyyy-MM-dd 00:00:01"
        );
        this.de = new Date(
          date.getFullYear(),
          date.getMonth() + 1,
          0
        ).timeFormat("yyyy-MM-dd 23:59:59");
      } else {
        this.ds = this.DateRange[0].timeFormat("yyyy-MM-dd 00:00:01");
        this.de = this.DateRange[1].timeFormat("yyyy-MM-dd 23:59:59");
      }
      {
        const _ = new Date(this.de);
        const date = _.getDate();
        _.setDate(date + 1);
        this.de = _.timeFormat("yyyy-MM-dd 23:59:59");
      }
      var data = {
        stime: this.ds,
        etime: this.de,
      };
      const { res, data: { uline, tline } = {} } = await this.$http.post(
        "/Management/UserManagement/GetLoginLine.ashx",
        data
      );
      if (res == 0) {
        this.loginTeamCounts = tline;
        this.loginUserCounts = uline;
      }
      this.loginTeamChart.changeData(this.loginTeamCounts);
      this.loginUserChart.changeData(this.loginUserCounts);
      this.loginDataLoading = false;
    },
    initLoginTeamChart() {
      const chart = new Chart({
        container: this.$refs.loginTeamChart,
        autoFit: true,
        // height: 500,
        theme: {
          styleSheet: {
            brandColor: "#81b7d7",
          },
        },
      });

      chart.data(this.loginTeamCounts);
      chart.scale({
        date: {
          nice: true,
          alias: "日期",
        },
        num: {
          nice: true,
          alias: "数量",
        },
      });
      // chart.tooltip({
      //   showCrosshairs: true,
      //   shared: true,
      // });

      chart.axis("date", {
        label: false,
      });
      chart.axis("num", {
        label: false,
      });

      chart.area().position("date*num");
      chart.line().position("date*num");
      // chart.option('scrollbar', {
      //   type: 'horizontal',
      //   animate: true,
      // })
      chart.render();
      this.loginTeamChart = chart;
    },
    initLoginUserChart() {
      const chart = new Chart({
        container: this.$refs.loginUserChart,
        autoFit: true,
        // height: 500,
        theme: {
          styleSheet: {
            brandColor: "#81b7d7",
          },
        },
      });

      chart.data(this.loginUserCounts);
      chart.scale({
        date: {
          nice: true,
          alias: "日期",
        },
        num: {
          nice: true,
          alias: "数量",
        },
      });
      // chart.tooltip({
      //   showCrosshairs: true,
      //   shared: true,
      // });

      chart.axis("date", {
        label: false,
      });
      chart.axis("num", {
        label: false,
      });

      chart.area().position("date*num");
      chart.line().position("date*num");
      // chart.option('scrollbar', {
      //   type: 'horizontal',
      //   animate: true,
      // })
      chart.render();
      this.loginUserChart = chart;
    },
  },
  async mounted() {
    this.initLoginTeamChart();
    this.initLoginUserChart();
    await this.handleGetData();
  },
};
